import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Button, { ButtonVariant } from "../components/Button";

// markup
const AgencePage = () => {
  return (
    <Layout
      title="Oups..."
      metaTitle="Agence d’UX Design responsable"
      metaTitleSuffix="Donut Panic"
    >
      <div className="flex flex-col items-center mt-8">
        <StaticImage
          src="../images/icons/donut-map.png"
          alt="donut carte"
          className="w-20 h-20"
        />
        <p className="my-8">
          La page que vous recherchez est indisponible. <br />
          <br />
          Faites un tour sur notre site pour trouver d’autres news à croquer !
        </p>
        <Button variant={ButtonVariant.secondary} text="Accueil" to="/" />
      </div>
    </Layout>
  );
};

export default AgencePage;
